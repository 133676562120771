import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  gss: {
    title: 'GSS',
    desc: 'Manage, organize and configure Supplements.',
    search: {
      navigate: 'Go to:'
    },
    routes: {
      adminConfig: {
        desc: 'Configure templates for usage as admin.',
        title: 'Configure Template'
      },
      adminselect: {
        desc: 'Open AdminUI for approving and/or setting default master template.',
        title: 'Set Default Master Template'
      },
      admintest: {
        desc: 'Build and run test cases for GSS services.',
        title: 'Test GSS Services'
      },
      comConfig: {
        desc: 'Configure templates for usage.',
        title: 'Configure Template'
      },
      comselect: {
        desc: 'Open ComUI to create a new template.',
        title: 'Create New Template'
      },
      dataservice: {
        desc: 'Manage available data for GSS features and templates.',
        title: 'Data Service'
      },
      designer: {
        desc: 'Design and create a fully new template.',
        title: 'Template Designer'
      },
      kpis: {
        desc: 'Get an overview about the GSS KPIs.',
        title: 'Analytics Board'
      },
      utilsjasperpdf: {
        desc: 'Convert a Base64 string into Jasper PDF file.',
        title: 'Base64 to Jasper PDF'
      }
    }
  }
};
var esES = {
  gss: {
    title: 'GSS',
    desc: 'Gestionar, organizar y configurar suplementos.',
    search: {
      navigate: 'Ir a:'
    },
    routes: {
      adminConfig: {
        desc: 'Configurar plantillas para el uso como administrador.',
        title: 'Configurar plantilla'
      },
      adminselect: {
        desc: 'Abrir la AdminUI para aprobar y/o establecer la plantilla maestra predeterminada.',
        title: 'Definir plantilla maestra predeterminada'
      },
      admintest: {
        desc: 'Crear y ejecutar casos de prueba para los servicios de GSS.',
        title: 'Probar servicios de GSS'
      },
      comConfig: {
        desc: 'Configurar plantillas para el uso.',
        title: 'Configurar plantilla'
      },
      comselect: {
        desc: 'Abrir ComUI para crear una nueva plantilla.',
        title: 'Crear nueva plantilla'
      },
      dataservice: {
        desc: 'Gestionar los datos disponibles para las características GSS y las plantillas.',
        title: 'Servicio de datos'
      },
      designer: {
        desc: 'Diseñar y crear una plantilla completamente nueva.',
        title: 'Diseñador de plantillas'
      },
      kpis: {
        desc: 'Obtener una visión general de los KPI de GSS.',
        title: 'Panel de análisis'
      },
      utilsjasperpdf: {
        desc: 'Convertir una cadena Base64 en un archivo Jasper PDF.',
        title: 'Base64 a Jasper PDF'
      }
    }
  }
};
var deDE = {
  gss: {
    title: 'GSS',
    desc: 'Manage, organize and configure Supplements.',
    search: {
      navigate: 'Direkt zu:'
    },
    routes: {
      adminConfig: {},
      adminselect: {
        desc: 'Administrations UI für das Freigeben und/oder erstellen von Standardvorlagen öffnen.',
        title: 'Neue Standardvorlage festlegen'
      },
      admintest: {
        desc: 'Erstellen und ausführen von Testfällen.',
        title: 'GSS Services Tests'
      },
      comConfig: {},
      comselect: {
        desc: 'ComUI öffnen um eine Vorlage anzulegen.',
        title: 'Neue Vorlage erstellen'
      },
      dataservice: {
        desc: 'Verwaltung von allen verfügbaren GSS Funktionen und Platzhalter.',
        title: 'Data Service'
      },
      designer: {
        desc: 'Designe und erstelle eine komplett neue Vorlage.',
        title: 'Vorlagen-Designer'
      },
      kpis: {
        desc: 'Überblick über die erfassten GSS Kennzahlen.',
        title: 'Analytics Board'
      },
      utilsjasperpdf: {
        desc: 'Konvertieren von Base64 in ein Jasper PDF.',
        title: 'Base64 zu Jasper PDF'
      }
    }
  }
};
const GSS_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGB,
  [SystemLanguage['es-ES']]: esES,
  [SystemLanguage['de-DE']]: deDE
};

/**
 * Generated bundle index. Do not edit.
 */

export { GSS_TRANSLATIONS };
