import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'gss',
  adminDataService: 'admin-data-service',
  commercialSelect: 'commercial-select',
  commercialConfiguration: 'commercial-configuration',
  adminSelect: 'admin-select',
  adminTest: 'admin-test',
  adminConfiguration: 'admin-configuration',
  utilsJasperPdf: 'utils-jasper-pdf',
  designer: 'designer',
  kpis: 'kpis'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.gss.title',
    description: 'external.gss.desc',
    icon: 'zui-icon-file-file-file',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_gss,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.adminDataService,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.dataservice.title',
        description: 'external.gss.routes.dataservice.desc',
        icon: 'zui-icon-edf-edf-default',
        showInMenu: true,
        cache: true,
        hierarchy: 10,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_dataservice']
        })
      }
    }, {
      path: ROUTE_PATHS.commercialSelect,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.comselect.title',
        description: 'external.gss.routes.comselect.desc',
        icon: 'zui-icon-enter-data',
        showInMenu: true,
        cache: false,
        isAdmin: false,
        hierarchy: 11,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_commercial_ui']
        })
      }
    }, {
      path: ROUTE_PATHS.commercialConfiguration,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.comConfig.title',
        description: 'external.gss.routes.comConfig.desc',
        icon: 'zui-icon-lock-lock-open',
        showInMenu: false,
        cache: false,
        isAdmin: false,
        hierarchy: 12,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_commercial_ui']
        })
      }
    }, {
      path: ROUTE_PATHS.adminSelect,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.adminselect.title',
        description: 'external.gss.routes.adminselect.desc',
        icon: 'zui-icon-file-file-add-file',
        showInMenu: true,
        cache: false,
        isAdmin: true,
        hierarchy: 13,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_admin_ui']
        })
      }
    }, {
      path: ROUTE_PATHS.adminTest,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.admintest.title',
        description: 'external.gss.routes.admintest.desc',
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 14,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_testing']
        })
      }
    }, {
      path: ROUTE_PATHS.adminConfiguration,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.adminConfig.title',
        description: 'external.gss.routes.adminConfig.desc',
        icon: 'zui-icon-lock-lock-open',
        showInMenu: false,
        cache: false,
        isAdmin: true,
        hierarchy: 15,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_admin_ui']
        })
      }
    }, {
      path: ROUTE_PATHS.utilsJasperPdf,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.utilsjasperpdf.title',
        description: 'external.gss.routes.utilsjasperpdf.desc',
        icon: 'zui-icon-service',
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 16,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_converter']
        })
      }
    }, {
      path: ROUTE_PATHS.designer,
      canActivate: [AUTH_GUARD],
      children: [{
        path: '',
        redirectTo: '/gssdesigner',
        pathMatch: 'full'
      }],
      data: {
        title: 'external.gss.routes.designer.title',
        description: 'external.gss.routes.designer.desc',
        icon: 'zui-icon-multi-site-creation',
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 17,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }, {
      path: ROUTE_PATHS.kpis,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.gss.routes.kpis.title',
        description: 'external.gss.routes.kpis.desc',
        icon: 'zui-icon-data-acquisition',
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 18,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_analytics']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
